<template>
  <div class="panel-page">
    <div class="panel-header">
      {{ hospital.name }} - {{ place.name }}
    </div>

    <div class="panel-body">
      <div class="history">
        <h2>Histórico de chamadas</h2>
        <st-tile v-for="item in historyItems" size="lg" :icon="['fad', 'user']" :centered="true" :key="item.id">
          <div class="tile-title">
            SENHA <strong>{{ item?.patient?.name || item.value || item.number }}</strong>
          </div>
          <template v-slot:action>
            {{ item.roomName }}
          </template>
        </st-tile>
      </div>

      <div class="current">
        <template v-if="currentPassword">
          <div class="password">
            <div class="text">Senha</div>
            <div class="value">{{ currentPassword?.name || currentPassword.value || currentPassword.number }}</div>
            <div class="detail">
              <span v-if="currentPassword.isPreferential">Preferencial</span>
            </div>
          </div>
          <div class="room">
            <div class="text">Local</div>
            <div class="value">{{ currentPassword.roomName }}</div>
          </div>
        </template>
      </div>
    </div>

    <div class="panel-footer">
      <div class="columns">
        <div class="column">{{ currentDateHour }}</div>
        <div class="column text-right text-small">Stenci</div>
      </div>
    </div>
  </div>
</template>

<script>
import alertSong from '@/assets/audio/alert-1.mp3';

export default {
  data() {
    return {
      items: [],
      notification: new Audio(alertSong),
      loading: false,
      publicId: this.$route.params.id,
      synth: window.speechSynthesis,
      speech: new window.SpeechSynthesisUtterance(),
      speaker: null,
      hospital: {
        name: '',
      },
      place: {
        name: '',
      },
      history: {
        max: 5,
      },
      currentPassword: null,
    };
  },
  mounted() {
    this.publicId = this.$route.params.id;
    this.load();
  },
  beforeUnmount() {
    this.stopRealtimeEvents();
  },
  computed: {
    currentDateHour() {
      return this.$filters.date(this.$store.state.now, 'DD/MM/YYYY HH:mm:ss');
    },
    historyItems() {
      const items = this.items.length > 1 ? this.items.slice(1) : [];
      return items.length > this.history.max
        ? items.slice(0, this.history.max)
        : items;
    },
  },
  methods: {
    callTicket(socketParams) {
      const initTextToSong = socketParams.name ? `${socketParams.name}`
        : `Senha: ${socketParams.number}`;

      this.currentPassword = socketParams;
      this.items = this.items.filter((item) => item.ticketId !== socketParams.ticketId && item._id !== socketParams.ticketId);
      this.items.unshift(socketParams);
      this.playSong(`${initTextToSong}, Vá até ${this.place.name}, ${socketParams.roomName}`);
    },
    callUnknownTicket(socketParams) {
      const initTextToSong = socketParams.name ? `${socketParams.name}`
        : `Senha: ${socketParams.number}`;

      this.currentPassword = socketParams;
      this.playSong(`${initTextToSong}, Vá até ${socketParams.roomName}`);
    },
    playSong(message) {
      this.speech.text = message;
      const voices = this.synth.getVoices();

      if (this.speaker) {
        const hospitalVoice = voices.find((voice) => voice.name === this.speaker.name);
        this.speech.rate = this.speaker.rate;
        this.speech.pitch = this.speaker.pitch;
        this.speech.lang = hospitalVoice.lang;
        this.speech.voice = hospitalVoice;
      } else {
        const defaultVoice = voices.find((voice) => voice.default);
        this.speech.rate = 1;
        this.speech.pitch = 1;
        this.speech.lang = defaultVoice.lang;
        this.speech.voice = defaultVoice;
      }

      this.notification.play();
      this.synth.speak(this.speech);
    },
    realtimeListener({ name, data }) {
      if (name === 'call-ticket') {
        this.callTicket(data);
      } else if (name === 'recall-ticket') {
        this.callTicket(data);
      } else if (name === 'call-unknown-ticket') {
        this.callUnknownTicket(data);
      }
    },
    startRealtimeEvents() {
      return this.$realtime().then((service) => {
        const channel = service.channels.get(this.place._id);
        channel.subscribe(this.realtimeListener);
      });
    },
    stopRealtimeEvents() {
      return this.$realtime().then((service) => {
        const channel = service.channels.get(this.place._id);
        channel.unsubscribe(this.realtimeListener);
      });
    },
    load() {
      this.$hospitalService
        .get(`/place/${this.publicId}`, {
          params: { 'type-id': 'public' },
        })
        .then(({ data }) => {
          this.place = data;
          this.hospital = data.hospital;
          this.speaker = data.hospital.speaker;

          this.$ticketService
            .get('/ticket', {
              params: {
                offset: 0,
                limit: 6,
                place: data._id,
                hospital: data.hospital._id,
                status: 'called',
              },
            })
            .then(({ data: ticketData }) => {
              this.items = ticketData;
              const [lastPassword] = ticketData;
              this.currentPassword = lastPassword;
            });

          this.startRealtimeEvents();
        }).catch(() => { });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.panel-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  .panel-header {
    border-bottom: $border-width solid $border-color;
    background-color: $dark-blue-color-400;
    color: $light-color;
    flex: 0;
    font-size: $font-size-lg * 3;
    font-weight: 600;
    padding: $layout-spacing-lg $layout-spacing-lg * 2;
  }

  .panel-body {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    display: flex;

    .history {
      flex: 1;
      padding: $layout-spacing-lg $layout-spacing-lg * 2;
      overflow-y: auto;

      .tile {
        background-color: $gray-color-light;
        border-radius: $border-radius;
        padding: $unit-7;
        margin-top: $layout-spacing-lg;

        .tile-icon {
          padding: 0 $layout-spacing-lg;

          svg {
            color: $dark-blue-color-700;
            font-size: $unit-10;
          }
        }

        .tile-title {
          font-size: $unit-10;
        }

        .tile-action {
          font-size: $unit-8;
        }
      }
    }

    .current {
      align-items: center;
      color: $dark-blue-color-800;
      flex: 0 0 40%;
      background-color: $dark-blue-color-200;
      padding: $layout-spacing-lg $layout-spacing-lg * 2;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .password,
      .room {
        .text {
          color: $gray-color-dark;
          font-size: $unit-10;
          line-height: normal;
          text-transform: uppercase;
        }

        .value {
          color: $dark-blue-color-800;
          font-size: $unit-16;
          font-weight: 600;
          line-height: normal;
          text-transform: uppercase;
        }
      }

      .password {
        .value {
          font-size: $unit-10 * 2;
        }

        .detail {
          color: $info-color;
          height: 2rem;
          font-size: $unit-7;
          font-weight: 600;
          text-transform: uppercase;
        }
      }

      .room {
        margin-top: $unit-12;
      }
    }
  }

  .panel-footer {
    border-top: $border-width solid $border-color;
    background-color: $dark-blue-color-400;
    color: $light-color;
    font-size: $font-size-lg * 2;
    flex: 0;
    padding: $layout-spacing-lg $layout-spacing-lg * 2;
  }

  #alert-song {
    position: absolute;
    z-index: -9999;
  }
}
</style>
