<template>
  <table class="table">
    <thead>
      <tr>
        <th>Senha</th>
        <th>Tipo</th>
        <th>Status</th>
        <th>Preferência</th>
        <th>Última chamada</th>
        <th>Qtd. Chamadas</th>
        <th>Local</th>
        <th>Excluir</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(ticket) in tickets" :key="ticket._id || ticket.ticketId">
        <td>{{ ticket.value || ticket.number }}</td>
        <td>{{ ticket.type }}</td>
        <td>{{ replaceStatus(ticket.status) }}</td>
        <td>{{ ticket.isPreferential ? 'Preferencial' : 'Normal' }}</td>
        <td>{{ ticket.lastCall ? new Intl.DateTimeFormat('pt-BR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          }).format(new Date(ticket.lastCall)) : '--'
        }}</td>
        <td>{{ ticket.callsQuantity }}</td>
        <td>{{ ticket.roomName || '----' }}</td>
        <td>
          <button class="btn btn-error" v-bind:class="ticket.inPromise && 'loading'"
            v-bind:disabled="['called', 'canceled'].includes(ticket.status)" @click="cancel(ticket)">
            <fa-icon :icon="['fad', 'trash']"></fa-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    tickets: {
      type: Object,
    },
  },
  emits: [
    'cancelTicket',
  ],
  methods: {
    replaceStatus(status) {
      const statusNames = {
        called: 'Chamado',
        pending: 'Pendente',
        finished: 'Finalizado',
        canceled: 'Cancelado',
      };

      return statusNames[status];
    },
    cancel(ticket) {
      this.$emit('cancelTicket', ticket);
    },
  },
};
</script>
