<template>
  <div class="column col-sm-12 col-md-12 col-lg-6 col-6 screen">
    <div class="card">
      <div class="card-header">
        <div class="card-title h5">{{ place.name }}</div>
      </div>
      <div class="card-body links">
        <div class="screen">
          <span class="h6">Painel</span>
          <div class="screen-input input-group">
            <input type="text" class="form-input" readonly :value="panelURL" />
            <a
              class="btn input-group-btn btn-icon tooltip"
              data-tooltip="Abrir link"
              :href="panelURL"
              target="_blank"
            ><fa-icon :icon="['fad', 'external-link']" class="menu-icon" /></a>
            <button
              class="btn input-group-btn btn-icon tooltip"
              data-tooltip="Copiar link"
              @click="copyToClipboard(panelURL)"
            ><fa-icon :icon="['fad', 'copy']" class="menu-icon" /></button>
          </div>
        </div>
        <div class="screen">
          <span class="h6">Totem</span>
          <div class="screen-input input-group">
            <input type="text" class="form-input" readonly :value="totemURL" />
            <a
              class="btn input-group-btn btn-icon tooltip"
              data-tooltip="Abrir link"
              :href="totemURL"
              target="_blank"
            ><fa-icon :icon="['fad', 'external-link']" class="menu-icon" /></a>
            <button
              class="btn input-group-btn btn-icon tooltip"
              data-tooltip="Copiar link"
              @click="copyToClipboard(totemURL)"
            ><fa-icon :icon="['fad', 'copy']" class="menu-icon" /></button>
          </div>
        </div>
        <div class="screen">
          <span class="h6">Código da Impressora</span>
          <div class="screen-input input-group">
            <input type="text" class="form-input" readonly :value="totemId" />
            <button
              class="btn input-group-btn btn-icon tooltip"
              @click="copyToClipboard(totemId)"
              data-tooltip="Copiar código"
            ><fa-icon :icon="['fad', 'copy']" class="menu-icon" /></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    place: {
      type: Object,
    },
  },
  computed: {
    baseURL() {
      return window.location.origin;
    },
    totemId() {
      return Array.isArray(this.place.printers) && this.place.printers.length > 0
        ? this.place.printers[0]
        : '';
    },
    panelPath() {
      return `/panel/${this.place.publicId}`;
    },
    totemPath() {
      return `/totem/${this.totemId}`;
    },
    panelURL() {
      return [this.baseURL, this.panelPath].join('');
    },
    totemURL() {
      return [this.baseURL, this.totemPath].join('');
    },
  },
  methods: {
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables';

.screen {
  margin-bottom: $layout-spacing-lg;

  .button-group {
    display: flex;
    flex-wrap: nowrap;
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: $layout-spacing-lg;
  }

  .screen-input {
    .btn {
      border-color: $border-color-dark;
      color: $primary-color;
      width: 2rem;
      &:hover {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $light-color;
      }
    }
  }
}
</style>
