<template>
  <div class="screens-page page-container">
    <div class="loading loading-lg" v-if="isLoading"></div>
    <div class="card" v-else>
      <div class="card-header">
        <div class="card-title h4">
          Controle de voz
        </div>
        <div class="card-subtitle">Aqui você consegue alterar as configurações de voz dos painéis de chamada.</div>
      </div>
      <div class="card-body">
        <div class="rows">
          <div class="columns">
            <div class="column col-12">
              <label for="voice" class="form-label text-center">Voz</label>
              <div class="input-centered">
                <select class="form-select" v-model="form.name" name="voice" id="voice">
                  <option v-for="voice in permittedVoices" v-bind:key="voice.name" v-bind:value="voice.name">
                    {{ voice.name }} ({{ voice.lang }})
                  </option>
                </select>
                <div v-if="form.localService" class="message-warning">
                  {{ form.warningMessage }}
                </div>
              </div>
              <div class="form-group">
                <label for="pitch" class="form-label text-center">Tom de voz</label>
                <div class="slider-input">
                  <span class="text-left">Muito grossa</span>
                  <input name="pitch" id="pitch" class="slider tooltip" type="range" step="0.1" min="0.1" max="2"
                    v-model="form.pitch" v-bind:data-tooltip="form.pitch">
                  <span class="text-right">Muito fina</span>
                </div>
              </div>
              <div class="form-group">
                <label for="rate" class="form-label text-center">Velocidade</label>
                <div class="slider-input">
                  <span class="text-left">Muito lento</span>
                  <input class="slider tooltip" name="rate" id="rate" type="range" step="0.1" min="0.1"
                    v-model="form.rate" v-bind:data-tooltip="form.rate" v-bind:max="form.maxRate">
                  <span class="text-right">Muito rápido</span>
                </div>
              </div>
              <div class="form-group">
                <label for="volume" class="form-label text-center">Volume</label>
                <div class="slider-input">
                  <span class="text-left">Sem som</span>
                  <input class="slider tooltip" name="volume" id="volume" type="range" step="10" min="0" max="100"
                    v-model="form.volume" v-bind:data-tooltip="form.volume">
                  <span class="text-right">Volume do dispositivo</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="phrase" class="form-label">Frase de teste</label>
          <div class="columns column mb-2">
            <div class="col-11">
              <input type="text" class="form-input mb-2" name="phrase" id="phrase" v-model="customPhrase">
            </div>
            <div class="col-1">
              <button class="btn btn-primary" @click="speak(customPhrase)">
                Testar voz
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="align-right mt-2">
          <button class="btn" @click="resetVoice">Restaurar ao padrão</button>
          <button class="btn btn-primary" @click="saveSpeaker">
            Salvar configuração
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      form: {},
      isLoading: true,
      places: [],
      customPhrase: 'Senha RP 001 vá até recepção, guichê 01',
      synth: window.speechSynthesis,
      speech: new window.SpeechSynthesisUtterance(),
      voices: [],
    };
  },
  mounted() {
    this.form = this.blankForm();
    this.loadVoices();
    this.loadSpeaker();
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.name': function () {
      const selectedVoice = this.permittedVoices.find((voice) => voice.name === this.form.name);
      this.form = {
        ...selectedVoice,
      };
      this.form.maxRate = selectedVoice.maxRate;
    },
  },
  computed: {
    permittedVoices() {
      return [
        {
          name: 'Microsoft Daniel - Portuguese (Brazil)',
          pitch: 1,
          rate: 1,
          volume: 100,
          lang: 'pt-BR',
          localService: true,
          maxRate: 10,
          warningMessage: 'Esta voz só é compatível com o sistema operacional Windows.',
        },
        {
          name: 'Microsoft Maria - Portuguese (Brazil)',
          pitch: 1,
          rate: 1,
          volume: 100,
          lang: 'pt-BR',
          localService: true,
          maxRate: 10,
          warningMessage: 'Esta voz só é compatível com o sistema operacional Windows.',
        },
        {
          name: 'Google português do Brasil',
          pitch: 1,
          rate: 1,
          volume: 100,
          lang: 'pt-BR',
          localService: true,
          default: true,
          maxRate: 2,
          warningMessage: 'Esta voz só é compatível com o navegador Google Chrome.',
        },
      ];
    },
  },
  methods: {
    loadVoices() {
      this.voices = this.synth.getVoices();

      if (this.voices.length) this.isLoading = false;

      this.synth.onvoiceschanged = () => {
        this.voices = this.synth.getVoices();
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 800);
      };
    },
    loadSpeaker() {
      this.$hospitalService.get(`/hospital/${localStorage.hospital}`).then(({ data }) => {
        const { speaker } = data;
        const selectedVoice = this.permittedVoices.find((voice) => voice.name === speaker.name);
        this.form = {
          ...selectedVoice,
          name: speaker.name,
          pitch: speaker.pitch,
          rate: speaker.rate,
          volume: speaker.volume,
          lang: speaker.lang,
        };
      });
    },
    saveSpeaker() {
      this.isLoading = true;

      const selectedVoice = this.permittedVoices.find((voice) => voice.name === this.form.name);

      this.$hospitalService.patch(`/hospital/${localStorage.hospital}`, {
        speaker: {
          name: this.form.name,
          pitch: this.form.pitch,
          rate: this.form.rate,
          volume: this.form.volume,
          lang: selectedVoice.lang,
        },
      }).then(() => {
        this.isLoading = false;
      });
    },
    speak(phrase) {
      this.synth.cancel();

      this.speech.text = phrase;
      this.speech.rate = this.form.rate;
      this.speech.pitch = this.form.pitch;
      this.speech.volume = this.form.volume;
      this.speech.lang = this.form.lang;

      const selectedVoice = this.voices.find((voice) => voice.name === this.form.name);
      this.speech.voice = selectedVoice;

      this.synth.speak(this.speech);
    },
    blankForm() {
      return this.permittedVoices.find((voice) => voice.default);
    },
    resetVoice() {
      this.form = this.blankForm();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.slider-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: $unit-4;
  margin-bottom: $unit-10;

  input {}

  span {
    width: $unit-12 * 3;
  }
}

.align-right {
  display: flex;
  justify-content: flex-end;

  :first-child {
    margin-right: $unit-2;
  }
}

.message-warning {
  margin-top: $unit-4;
  background-color: $orange-color-400;
  border-radius: $border-radius;
  color: $primary-font-color;
  padding: $layout-spacing $layout-spacing-lg;
  margin-bottom: $layout-spacing-lg;
}

.input-centered {
  display: flex;
  flex-direction: column;
  margin-top: $unit-4;
  margin-bottom: $unit-12;
}
</style>
