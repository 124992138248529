<template>
  <div class="password-page">
    <div class="text-center" v-if="processing">
      <div class="loading loading-lg"></div>
      <div class="mt-2 text-dark">Imprimindo...</div>
    </div>
    <st-empty
      v-else-if="error"
      class="error-block"
      :icon="['fad', 'exclamation-triangle']"
      title="Erro ao gerar senha"
      subtitle="Tente novamente, se o problema persistir, fale com um atendente."
    >
      <div class="empty-action">
        <button class="btn btn-lg btn-primary" @click="closeError">OK</button>
      </div>
      <pre><code>{{ error.toString() }}</code></pre>
    </st-empty>
    <div v-else>
      <div class="back-step" v-if="showPreference">
        <button class="btn btn-primary" @click="hidePreference">
          <fa-icon :icon="['far', 'arrow-left']" />
        </button>
      </div>

      <div class="options" v-if="!showPreference">
        <type-option
          v-for="(type, i) in types"
          v-bind="type"
          :key="i"
          @click="handleSelectType(type)"
        ></type-option>
      </div>

      <div class="options" v-else>
        <preference-option
          v-for="(preference, i) in preferenceOptions"
          v-bind="preference"
          :key="i"
          @select="generate"
        ></preference-option>
      </div>
    </div>
  </div>
</template>

<script>
import TypeOption from './components/Option.vue';
import PreferenceOption from './components/Preference.vue';

export default {
  components: {
    TypeOption,
    PreferenceOption,
  },
  data() {
    return {
      id: null,
      types: [],
      place: {},
      hospital: {},
      processing: false,
      selectedType: '',
      showPreference: false,
      error: null,
      printerModel: localStorage.printerModel,
      preferenceOptions: [
        {
          icon: ['fad', 'user'],
          isPreferential: false,
          title: 'Normal',
        },
        {
          icon: ['fad', 'user-headset'],
          isPreferential: true,
          title: 'Preferencial',
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.id = localStorage.getItem('totemId');
      let newId = null;

      if (this.$route.params.id) {
        newId = this.$route.params.id;
      } else if (this.$route.query.id) {
        newId = this.$route.query.id;
      } else if (!this.id) {
        newId = localStorage.getItem('printerId');
      }

      if (newId) {
        this.id = newId;
      }

      if (this.id) {
        if (newId) {
          localStorage.setItem('totemId', newId);
          this.$router.push('/totem');
        }
        this.load();
      } else {
        this.showError(new Error('ID do totem não foi informado'));
      }
    },
    showError(e) {
      this.error = e;
      if (this.types.length > 0) {
        setTimeout(() => {
          this.error = false;
        }, 1000 * 60);
      }
    },
    closeError() {
      this.error = false;
    },
    async load() {
      try {
        const { data } = await this.$hospitalService.get(`/place/${this.id}`, {
          params: {
            'type-id': 'public',
          },
        });
        this.place = data;
        this.types = data.attendance;
        this.hospital = data.hospital;
      } catch (e) {
        this.showError(e);
      }
    },
    handleSelectType(type) {
      this.selectedType = type.title;
      this.prefix = type.prefix;
      if (type.hasPreference) {
        this.showPreference = true;
      } else {
        this.generate(false);
      }
    },
    hidePreference() {
      this.showPreference = false;
    },
    generate(isPreferential) {
      if (this.processing) return;

      this.processing = true;

      const postData = {
        type: this.selectedType,
        isPreferential,
        place: this.place._id,
        hospital: this.hospital._id,
        printer: this.id,
        paddingNumber: this.place.paddingNumber,
        prefix: this.prefix,
        printerModel: this.place.printerModel,
      };

      this.$ticketService.post('/ticket', postData)
        .catch(this.showError)
        .finally(() => {
          this.processing = false;
          this.hidePreference();
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.password-page {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  .options {
    .tile {
      background-color: $gray-color-ultralight;
      border: $border-width solid $gray-color-light;
      border-radius: $border-radius;
      color: $primary-color;
      cursor: pointer;
      margin: $layout-spacing-lg;
      width: 28rem;

      .tile-title {
        font-weight: 600;
      }

      .tile-subtitle {
        color: $gray-color-dark;
        margin-top: $border-width-lg;
      }
    }
  }

  .back-step {
    position: absolute;
    left: $layout-spacing-lg * 2;
    top: $layout-spacing-lg * 2;

    button {
      border-radius: 100%;
      width: 70px;
      height: 70px;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .error-block {
    background-color: rgba($error-color, .5);
    border: $border-width solid $border-color-dark;
    svg {
      color: $error-color;
    }
    .btn {
      width: 10rem;
    }
    pre {
      margin: $page-spacing 0 0 0;
    }
  }
}
</style>
