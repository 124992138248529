<template>
  <st-tile :icon="icon" :centered="true" size="xl" @click="selectedPreference">
    <div class="tile-title">{{ title }}</div>
    <div class="tile-subtitle">Gerar senha de atendimento</div>
  </st-tile>
</template>

<script>
export default {
  emits: ['select'],
  props: {
    icon: {
      type: Array,
      default: () => ['fad', 'print'],
    },
    type: {
      type: String,
    },
    isPreferential: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    prefix: {
      type: String,
    },
  },
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    selectedPreference() {
      this.$emit('select', this.isPreferential);
    },
  },
};
</script>
