<template>
  <div class="column col-sm-12 col-md-12 col-lg-4 col-4 mb-2">
    <div class="card">
      <div class="card-header">
        <div class="card-title h5">{{ place.name }}</div>
      </div>
      <div class="card-body">
        <p><span class="text-primary">{{ stats?.total || 0 }}</span> total de senhas geradas</p>
        <p><span class="text-primary">{{ stats?.called || 0 }}</span> senhas chamadas</p>
        <p><span class="text-primary">{{ stats?.pending || 0 }}</span> senhas pendentes</p>
      </div>
      <div class="card-footer">
        <div class="btn-group btn-group-block">
          <button class="btn btn-primary" v-bind:class="newTickets && 'badge'" @click="openModal">Visualizar
            senhas</button>
        </div>
      </div>
    </div>

    <st-modal class="modal" :size="'xl'" v-model="modal" v-bind:title="`Lista de Senhas: ${place.name}`">
      <div class="loading loading-lg" v-if="isLoading"></div>
      <div v-else-if="tickets.length">
        <tickets-table :tickets="tickets" @cancelTicket="cancelTicket" />
      </div>
      <template v-else>
        <div class="empty">
          <div class="empty-icon">
            <fa-icon :icon="['fad', 'ticket']" size="2x"></fa-icon>
          </div>
          <p class="empty-title h5">Não há registros</p>
          <p class="empty-subtitle">Feche o modal e aguarde aparecer a notificação avisando que há novos registros</p>
        </div>
      </template>
    </st-modal>
  </div>
</template>

<script>
import TicketsTable from './TicketsTable.vue';

export default {
  components: {
    TicketsTable,
  },
  props: {
    place: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      newTickets: false,
      modal: false,
      modalTickets: [],
      stats: {
        pending: this.place.stats?.pending || 0,
        called: this.place.stats?.called || 0,
        total: this.place.stats?.total || 0,
      },
      tickets: [],
    };
  },
  mounted() {
    this.startRealtimeEvents();
  },
  beforeUnmount() {
    this.stopRealtimeEvents();
  },
  methods: {
    openModal() {
      this.loadTickets();
      this.newTickets = false;
      this.modal = true;
    },
    cancelTicket(ticket) {
      ticket.inPromise = true;
      this.$ticketService.patch(`/ticket/finish/${ticket._id}?cancel=true`)
        .then(({ data }) => {
          ticket.status = data.status;
          ticket.inPromise = false;
          this.stats.pending -= 1;
        });
    },
    realtimeListener({ name, data }) {
      const { ticketId } = data;

      if (name === 'recall-ticket') return;

      this.newTickets = true;

      if (name === 'create-ticket') {
        this.tickets.unshift(data);

        this.stats.total += 1;
        this.stats.pending += 1;
        return;
      }

      this.stats.pending -= 1;
      this.stats.called += 1;

      this.tickets = this.tickets.map((ticket) => {
        if (ticket.ticketId === ticketId || ticket._id === ticketId) {
          ticket = data;
        }
        return ticket;
      });
    },
    startRealtimeEvents() {
      return this.$realtime().then((service) => {
        const channel = service.channels.get(this.place._id);
        channel.subscribe(this.realtimeListener);
      });
    },
    stopRealtimeEvents() {
      return this.$realtime().then((service) => {
        const channel = service.channels.get(this.place._id);
        channel.unsubscribe(this.realtimeListener);
      });
    },
    loadTickets() {
      this.$ticketService.get('/ticket', {
        params: {
          place: this.place._id,
          hospital: localStorage.hospital,
          limit: 100,
          offset: 0,
        },
      }).then(({ data }) => {
        this.tickets = data;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.custom-modal {
  div.modal-container {
    width: 80% !important;
  }
}
</style>
