<template>
  <div class="places-page page-container">
    <div class="card">
      <div class="card-header">
        <div class="card-title h4">
          Senhas
        </div>
        <div class="card-subtitle">Aqui você consegue ver as quantidades de senhas atendidas e pendentes das últimas
          <b>12
            horas</b>, separado por local de atendimento.
        </div>
      </div>
      <div class="card-body">
        <div class="columns">
          <place v-for="(place, index) in places" :place="place" :key="index" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Place from './components/Place.vue';

export default {
  components: {
    Place,
  },
  data() {
    return {
      title: 'Senhas',
      places: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$hospitalService.get('/place', {
        params: {
          offset: 0,
          limit: 50,
          hospital: localStorage.hospital,
        },
      })
        .then(({ data: placesData }) => {
          this.$ticketService.get('/stats', {
            params: {
              hospital: localStorage.hospital,
            },
          }).then(({ data: statsData }) => {
            this.places = placesData.map((place) => ({ ...place, stats: statsData[place._id] }));
          });
        })
        .catch(() => { });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';
</style>
