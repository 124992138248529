<template>
  <st-tile :icon="icon" :centered="true" size="xl">
    <div class="tile-title">{{ title }}</div>
    <div class="tile-subtitle">Gerar senha de atendimento</div>
  </st-tile>
</template>

<script>
export default {
  props: {
    icon: {
      type: Array,
      default: () => ['fad', 'print'],
    },
    title: {
      type: String,
    },
    prefix: {
      type: String,
    },
  },
};
</script>
